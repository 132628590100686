import _ from 'lodash'
import React, { Component } from 'react'
import { render } from 'react-dom'
import styled, { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import StandardButton from 'svr/component-lib/Generic/Buttons/StandardButton'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import EditDeleteTextInput from 'svr/component-lib/Generic/TextInputs/EditDeleteTextInput'
import DropdownMenu from 'svr/component-lib/Manager/Menus/DropdownMenu'

const Main = styled.div`
  font-family: ${props => props.theme.fontFamily};
  font-style: normal;
  font-size: ${props => props.theme.fontSize.medium};
  margin: 15px 0;
`

const Header = styled.div`
  font-style: normal;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: ${props => props.theme.fontSize.medium};
  margin: 25px 0 10px 0;
  font-weight: bold;
  color: ${props => props.theme.color.grey};
`

const COUNT_VENUES_TYPE = {
  ONE: 'one',
  MULTIPLE: 'multiple',
}

const VENUE_TYPE = {
  VENUE: 'venue',
  GROUP: 'group',
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      primary: null,
      multiVenues: [],
      countOfVenuesForWidget: COUNT_VENUES_TYPE.ONE,
      typeOfPrimaryVenue: VENUE_TYPE.VENUE,
    }
  }

  onChoosePrimaryVenue = choices => {
    this.setState({
      primary: choices[0],
    })
  }

  onChooseMultiVenues = choices => {
    this.setState({
      multiVenues: choices,
    })
  }

  getExtras = (primaryVenue, businessUrl) => ({
    setup: {
      external_business_id: primaryVenue.id,
      timezone: primaryVenue.time_zone,
      currency: primaryVenue.currency,
      business_vertical: 'SERVICES',
    },
    business_config: {
      business: {
        name: primaryVenue.name,
      },
      ig_cta: {
        enabled: true,
        cta_button_text: 'Reserve',
        cta_button_url: businessUrl,
      },
      page_cta: {
        enabled: true,
        cta_button_text: 'Book Now',
        cta_button_url: businessUrl,
        below_button_text: 'Powered by SevenRooms',
      },
    },
    repeat: false,
  })

  onClickRedirect = () => {
    const primaryVenue = this.props.venues[this.state.primary.value]
    let ctaButtonUrl = this.props.templateLink.replace('#primary_venue#', primaryVenue.url)

    if (this.state.countOfVenuesForWidget === COUNT_VENUES_TYPE.MULTIPLE) {
      let multiVenues = _.clone(this.state.multiVenues)
      if (this.state.typeOfPrimaryVenue === VENUE_TYPE.VENUE) {
        const found = _.find(multiVenues, venue => venue.id === this.state.primary.id)
        if (!found) {
          multiVenues.push(this.state.primary)
        }
      } else {
        multiVenues = _.filter(multiVenues, venue => venue.id !== this.state.primary.id)
      }
      const urls = _.map(multiVenues, 'url').join(',')
      ctaButtonUrl = `${ctaButtonUrl}&venues=${urls}`
    }

    let redirectUrl = this.props.redirectUri
    const extras = this.getExtras(primaryVenue, ctaButtonUrl)
    const concat = redirectUri.indexOf('?') !== -1 ? '&' : '?'

    redirectUrl += `${concat}extras=${encodeURIComponent(JSON.stringify(extras))}`

    window.location.replace(redirectUrl)
  }

  deleteFromMultiVenues = index => {
    const venueToDelete = this.state.multiVenues[index]
    const multiVenues = _.filter(this.state.multiVenues, venue => venue.id !== venueToDelete.id)
    this.setState({
      multiVenues,
    })
  }

  handleSelectOneOrMultipleVenues = value => {
    this.setState({
      countOfVenuesForWidget: value,
    })
  }

  renderSelectPrimaryVenue(venueChoices) {
    const label = this.state.countOfVenuesForWidget === COUNT_VENUES_TYPE.MULTIPLE ? 'Select primary venue or group' : 'Select Venue'
    return (
      <div>
        <Header>{label.toUpperCase()}</Header>
        <DropdownMenu
          key="select-main-venues"
          id="select-main-venues"
          choices={venueChoices}
          selectedValues={[this.state.primary]}
          placeholder="Type venue name"
          isLightTheme
          style={{ width: '100%' }}
          isFullSizeMenu
          searchingEnabled
          onChangeHandler={this.onChoosePrimaryVenue}
        />
      </div>
    )
  }

  renderSelectMultipleVenue(venueChoices) {
    const label = this.state.typeOfPrimaryVenue === VENUE_TYPE.VENUE ? 'Select secondary venues' : 'Select all venues'
    return this.state.countOfVenuesForWidget === COUNT_VENUES_TYPE.MULTIPLE ? (
      <div>
        <Header>{label.toUpperCase()}</Header>
        <DropdownMenu
          key="select-multiple-venues"
          id="select-multiple-venues"
          choices={venueChoices}
          selectedValues={this.state.multiVenues}
          placeholder="Add venues"
          isLightTheme
          style={{ width: '100%' }}
          isMultiSelect
          searchingEnabled
          isFullSizeMenu
          onChangeHandler={this.onChooseMultiVenues}
          isSelectAllEnabled
        />
        {_.map(this.state.multiVenues, (venue, idx) => (
          <div style={{ width: '300px' }} key={idx}>
            <EditDeleteTextInput
              idx={idx}
              uniqueId={venue.id}
              editDeleteInputContainerStyles={{ padding: '10px 0px' }}
              value={venue.name}
              deleteHandler={this.deleteFromMultiVenues}
              maxWidth={300}
              disabled
            />
          </div>
        ))}
      </div>
    ) : null
  }

  renderRadioIsMultiple() {
    return _.keys(this.props.venues).length > 1 ? (
      <div>
        <Header>Display availability for:</Header>
        <RadioList
          field="countOfVenuesForWidget"
          selectedValue={this.state.countOfVenuesForWidget}
          options={[
            { value: COUNT_VENUES_TYPE.ONE, label: 'One venue only' },
            { value: COUNT_VENUES_TYPE.MULTIPLE, label: 'Multiple venues' },
          ]}
          onClick={this.handleSelectOneOrMultipleVenues}
        />
      </div>
    ) : null
  }

  handleSelectTypeOfPrimaryVenue = value => {
    this.setState({
      typeOfPrimaryVenue: value,
    })
  }

  renderRadioIsGroup() {
    return this.state.countOfVenuesForWidget === COUNT_VENUES_TYPE.MULTIPLE ? (
      <div>
        <Header>Is your selection a venue or a hospitality group?</Header>
        <RadioList
          field="typeOfPrimaryVenue"
          selectedValue={this.state.typeOfPrimaryVenue}
          options={[
            {
              value: VENUE_TYPE.VENUE,
              label: 'My selection is a venue (e.g. Five Figs Restaurant)',
            },
            {
              value: VENUE_TYPE.GROUP,
              label: 'My selection is a restaurant group or hospitality group (e.g. SevenRooms Hospitality Group)',
            },
          ]}
          onClick={this.handleSelectTypeOfPrimaryVenue}
        />
      </div>
    ) : null
  }

  getIntegratingPlatformText = () => (this.props.isInstagram ? 'Instagram' : 'Facebook')

  render() {
    let venueChoices = _.map(this.props.venues, (value, key) => ({
      ...value,
      isSelected: false,
      value: key,
      name: value.name,
      hexColor: 'black',
    }))
    venueChoices = _.sortBy(venueChoices, item => item.name)
    const disabled = !(
      (this.state.countOfVenuesForWidget === COUNT_VENUES_TYPE.ONE && this.state.primary) ||
      (this.state.countOfVenuesForWidget === COUNT_VENUES_TYPE.MULTIPLE && this.state.primary && this.state.multiVenues.length)
    )

    return (
      <ThemeProvider theme={Theme}>
        <Main>
          <div>Configure your {this.getIntegratingPlatformText()} booking widget</div>
          {this.renderRadioIsMultiple()}
          {this.renderSelectPrimaryVenue(venueChoices)}
          {this.renderRadioIsGroup()}
          {this.renderSelectMultipleVenue(venueChoices)}
          <div style={{ padding: '20px 0 0 0' }}>
            <StandardButton disabled={disabled} onClick={this.onClickRedirect} customButton={{ width: '150px' }}>
              Save and continue
            </StandardButton>
          </div>
        </Main>
      </ThemeProvider>
    )
  }
}

App.propTypes = {
  venues: React.PropTypes.object,
  templateLink: React.PropTypes.string,
  redirectUri: React.PropTypes.string,
}

render(
  <App venues={integrationVenues} templateLink={templateLinkForPlatformReservation} redirectUri={redirectUri} isInstagram={isInstagram} />,
  document.getElementById('link-instagram-widget')
)
